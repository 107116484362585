import { cyan, pink } from '@material-ui/core/colors';
import { createMuiTheme } from '@material-ui/core/styles';


const breakpointValues = {
    xs: 480,
    sm: 640,
    md: 960,
    lg: 1280,
    xl: 1920,
};


const theme = createMuiTheme({
    palette: {
        primary: cyan,
        secondary: pink,
    },
    breakpoints: {
        values: breakpointValues,
    },
    typography: {
        h1: {
            fontSize: '5rem',
            [`@media (max-width: ${breakpointValues.sm}px)`]: {
                fontSize: '4rem',
            },
            [`@media (max-width: ${breakpointValues.xs}px)`]: {
                fontSize: '3.4rem',
            },
        },
        h2: {
            [`@media (max-width: ${breakpointValues.xs}px)`]: {
                fontSize: '3.1rem',
            },
        },
        h3: {
            [`@media (max-width: ${breakpointValues.sm}px)`]: {
                fontSize: '2.4rem',
            },
        },
        h4: {
            fontSize: '2.125rem',
            [`@media (max-width: ${breakpointValues.sm}px)`]: {
                fontSize: '1.6rem',
            },
        },
        h5: {
            [`@media (max-width: ${breakpointValues.sm}px)`]: {
                fontSize: '1.3rem',
            },
        },
        body1: {
            fontSize: '1.1rem',
        },
    },
    overrides: {
        MuiPickersToolbarText: {
            toolbarBtnSelected: {
                color: '#fff',
            },
        },
        MuiButton: {
            outlinedPrimary: {
                borderColor: cyan[600],
                color: cyan[600],
            }
        },
    },
    props: {
        MuiTooltip: {
            PopperProps: {
                disablePortal: true,
            },
        },
    }
});

theme.palette.primary.light = '#79ddea';
theme.palette.primary.lighter = '#d0faff';


console.log(theme);

export default theme;