import { xFetch } from 'Utils';
import { joinGroup } from 'Utils/socket';

export const ACTIONS = {
    FETCH_USERS: 'Fetching user data',
    FETCH_UNI: 'Fetching university data',
    FETCH_CLASSES: 'Fetching class data',
    FETCH_CHATS: 'Fetching chat data',
    FETCH_TASKS: 'Fetching task data',
    FETCH_LECTURERS: 'Fetching Lec data',
    FETCH_ADMINS: 'Fetching Admin data',
    SAVE_UNIT: 'Saving unit',
    SAVE_PROGRAMME: 'Saving programme',
    LOG_OUT: 'Logging out',
}

export const fetchData = (pendingAction) => {
    return async (dispatch, getState) => {
        if (!pendingAction) {
            throw new Error('Param pendingAction is required');
        }

        const { url, type } = (() => {
            switch (pendingAction) {
                case ACTIONS.FETCH_USERS: return { url: '/api/users', type: 'user' }
                case ACTIONS.FETCH_UNI: return { url: '/api/universities', type: 'university' }
                case ACTIONS.FETCH_CLASSES: return { url: '/api/classes', type: 'classes' }
                case ACTIONS.FETCH_CHATS: return { url: '/api/classes/groups/chats', type: 'chats' }
                case ACTIONS.FETCH_TASKS: return { url: '/api/classes/groups/tasks', type: 'tasks' }
                case ACTIONS.FETCH_LECTURERS: return { url: '/api/users/lecturers', type: 'lecturers' }
                case ACTIONS.FETCH_ADMINS: return { url: '/api/users/admins', type: 'admins' }
            }
        })()

        dispatch({
            type: 'user/fetchedData',
            pendingAction
        });

        try {
            dispatch(setPendingAction(pendingAction));
            const opts = {
                headers: {
                    "Accept": "Application/JSON"
                }
            }
            const { data, status } = await xFetch(url, opts);
            if (status === 200) {
                dispatch({
                    type: 'user/recievedData',
                    payload: { [type]: data },
                });
                if (type === 'classes') {
                    const classes = data || [];
                    const { user } = getState();
                    classes.forEach(c => {
                        let isLec = c.lecturerId == user.userId || c.asstLecturerId == user.userId;
                        (c.groups || []).forEach(g => {
                            if (isLec || g?.members.some(({ userId }) => userId == user.userId)) {
                                joinGroup(g);
                            }
                        })
                    });
                    dispatch(fetchData(ACTIONS.FETCH_CHATS));
                }
            }
        } catch (err) {
            console.error(url, err);
        }
        dispatch(unsetPendingAction(pendingAction));
    }
}

export const recieveNewChat = (payload) => ({
    type: 'system/recieveChat',
    payload
});

export const setSelectedClass = (selectedClass) => ({
    type: 'user/selectedClass',
    payload: selectedClass
})



export const setPendingAction = (pending) => ({
    type: 'system/setPendingAction',
    payload: pending
})
export const unsetPendingAction = (pending) => ({
    type: 'system/unsetPendingAction',
    payload: pending
})

export const setRole = (role) => {
    return async (dispatch, getState) => {
        const {user} = getState();
        if(!user) return;
        
        let newRole = role;
        if(role == 'administrator' && user.role !== 'administrator') {
            newRole = 'lecturer';
        }
        if(role == 'lecturer' && user.role !== 'administrator' && user.role !== 'lecturer') {
            newRole = 'student';
        }
        
        return dispatch({
            type: 'user/changedRole',
            payload: role
        })
    }
}

export const setLecClassPeriod = (period) => ({
    type: 'lec/changedPeriod',
    payload: period
})

export const login = (auth) => {
    return async dispatch => {
        if (auth) {
            await xFetch('/api/login', {
                method: 'POST',
                body: auth
            });
        } else {
            const status = window.localStorage.getItem('isLoggedIn') ||
                /isLoggedIn=true/.test(window.document.cookie);
            if (!status) return;
        }
        window.localStorage.setItem('isLoggedIn', true);

        const promises = [];
        promises.push(dispatch(fetchData(ACTIONS.FETCH_USERS)));
        promises.push(dispatch(fetchData(ACTIONS.FETCH_UNI)));
        promises.push(dispatch(fetchData(ACTIONS.FETCH_ADMINS)));
        promises.push(dispatch(fetchData(ACTIONS.FETCH_LECTURERS)));
        promises.push(dispatch(fetchData(ACTIONS.FETCH_CLASSES)));
        promises.push(dispatch(fetchData(ACTIONS.FETCH_CHATS)));
        promises.push(dispatch(fetchData(ACTIONS.FETCH_TASKS)));
        await Promise.all(promises);
    }
}

export const logout = () => {
    return async (dispatch) => {
        xFetch('/api/login', { method: 'DELETE' });
        window.localStorage.removeItem('isLoggedIn');
        dispatch({
            type: 'user/loggedOut'
        });
    }
}




/**
 * @typedef Notification
 * @type {{
 *  message: string,
 *  variant: "default"|"info"|"success"|"error"|"warning",
 *  persist: boolean,
 *  autoHideDuration: number,
 *  action: Node|null,
 *  onClose: Function
 * }}
 */
/**
 * @param {Notification} payload - notification details
 */
export const addNotification = (payload) => {
    return dispatch => {
        const key = Math.random().toString(36).slice(2);
        const addTime = new Date();

        dispatch({
            type: 'notification/added',
            payload: { ...payload, key, addTime }
        });

        return key;
    }
}

/**
 * @param {Notification} payload - notification details
 * @param {string} payload.key - notification identifier
 */
export const editNotification = (payload) => {
    return dispatch => {
        const newKey = Math.random().toString(36).slice(2);
        const addTime = new Date();
        dispatch({
            type: 'notification/edited',
            payload: { ...payload, newKey, addTime }
        });

        return newKey;
    }
}

/**
 * @param {string} key - notification identifier
 */
export const dismissNotification = (key) => {
    return {
        type: 'notification/dismissed',
        payload: key
    }
}




export const makeNetworkRequest = ({
    url, method, body, fetchData: getData,
    processMsg = 'Processing', successMsg = 'Success', throwErr = true
}) => {
    return async dispatch => {
        let key;
        if (processMsg !== null) {
            key = dispatch(addNotification({
                message: processMsg,
                action: null,
                persist: true,
            }));
        }
        try {
            const resp = await xFetch(url, {
                method,
                body,
            });
            if (getData) {
                await dispatch(fetchData(getData));
            }
            if (successMsg !== null) {
                dispatch(editNotification({
                    key,
                    message: successMsg,
                }));
            }

            resp.ntfKey = key;

            return resp
        } catch (err) {
            dispatch(editNotification({
                key,
                message: err.toString(),
                persist: true,
            }));
            // TODO: if 401 open login in new tab then close after
            // if (err.status === 401) {
            //     const link = document.createElement('a');
            //     link.href = '/login?reauth=true';
            //     link.target = '_blank';
            //     link.click();
            // }

            if (throwErr) throw err;
        }
    }
}

