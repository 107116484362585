import {
    Button, DialogActions, DialogContent, DialogTitle, TextField, Typography
} from '@material-ui/core';
import MuiDialog from '@material-ui/core/Dialog';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';


function Alert(props) {
    return (
        <Dialog
            maxWidth='md' disableBackdropClick
            onClose={props.onClose} open={true}
            onKeyDown={e => {
                if (e.key === 'Enter') {
                    props.onClose();
                    e.stopPropagation();
                    e.preventDefault();
                } else if (e.key === 'Escape') {
                    props.onClose();
                    e.stopPropagation();
                    e.preventDefault();
                }
            }}
        >
            {props.title &&
                <DialogTitle>
                    {props.title}
                </DialogTitle>
            }
            <DialogContent >
                <Typography>
                    {props.children}
                </Typography>
            </DialogContent>
            <DialogActions >
                <Button color='primary' onClick={e => props.onClose()} >
                    {props.okText}
                </Button>
            </DialogActions>
        </Dialog>
    );
}


function Confirm(props) {
    const closeHandler = (val) => {
        if (val === true) return props.onClose(true);
        props.onClose();
    }

    return (
        <Dialog
            maxWidth='md' disableBackdropClick
            onClose={closeHandler} open={true}
            onKeyDown={e => {
                if (e.key === 'Enter') {
                    closeHandler(true);
                    e.stopPropagation();
                    e.preventDefault();
                } else if (e.key === 'Escape') {
                    closeHandler();
                    e.stopPropagation();
                    e.preventDefault();
                }
            }}
        >
            {props.title &&
                <DialogTitle>
                    {props.title}
                </DialogTitle>
            }
            <DialogContent >
                <Typography>
                    {props.children}
                </Typography>
            </DialogContent>
            <DialogActions >
                <Button color='primary' onClick={e => closeHandler()} >
                    {props.cancelText}
                </Button>
                <Button color='primary' onClick={e => closeHandler(true)} >
                    {props.okText}
                </Button>
            </DialogActions>
        </Dialog>
    );
}


function Prompt(props) {
    const [text, setText] = useState('');

    const closeHandler = (val) => {
        if (typeof val === 'string') return props.onClose(val);
        props.onClose(undefined);
    }

    return (
        <Dialog
            maxWidth='md' disableBackdropClick
            onClose={closeHandler} open={true}
            onKeyDown={e => {
                if (e.key === 'Enter') {
                    closeHandler(text);
                    e.stopPropagation();
                    e.preventDefault();
                }else if (e.key === 'Escape') {
                    closeHandler();
                    e.stopPropagation();
                    e.preventDefault();
                }
            }}
        >
            {props.title &&
                <DialogTitle>
                    {props.title}
                </DialogTitle>
            }
            <DialogContent >
                <Typography>
                    {props.children}
                </Typography>
                <TextField
                    value={text} onChange={e => setText(e.target.value)}
                    variant='standard' fullWidth autoFocus
                />
            </DialogContent>
            <DialogActions >
                <Button color='primary' onClick={e => closeHandler()} >
                    {props.cancelText}
                </Button>
                <Button color='primary' onClick={e => closeHandler(text)} >
                    {props.okText}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

function AlertManager() {
    const [props, setProps] = useState({});
    const [type, setType] = useState();

    useEffect(() => {
        window.showAlert = function (txt, onClose, otherProps = {}) {
            setProps({
                children: txt,
                onClose: _ => { setType(null); onClose && onClose() },
                ...otherProps,
            });
            setType('alert')
        }

        window.showConfirm = function (txt, onClose, otherProps = {}) {
            setProps({
                children: txt,
                onClose: opt => { setType(null); onClose && onClose(opt) },
                ...otherProps,
            });
            setType('confirm');
        }

        window.showPrompt = function (txt, onClose, otherProps = {}) {
            setProps({
                children: txt,
                onClose: val => { setType(null); onClose && onClose(val) },
                ...otherProps,
            });
            setType('prompt');
        }
    }, [])


    const defaultProps = {
        cancelText: 'Cancel',
        okText: 'Ok',
        title: '',
    }

    const showDialog = type => {
        switch (type) {
            case 'alert': return <Alert {...defaultProps} {...props} />
            case 'confirm': return <Confirm {...defaultProps} {...props} />
            case 'prompt': return <Prompt {...defaultProps} {...props} />
            default: return null;
        }
    }

    return showDialog(type);
}



/**@type {MuiDialog} */
const Dialog = styled(MuiDialog)`
    .MuiDialog-paper {
        overflow: hidden;
        min-height: 120px; 
    }
    .MuiDialogTitle-root {
        text-align: left; 
        min-width: 320px;
        padding-bottom: 0;
    }
    .MuiDialogContent-root {
        text-align: left; 
        min-width: 320px;
        padding: 24px;
        padding-top: 8px;
    }
    .MuiDialogActions-root {
        display: flex;
        justify-content: flex-end;
    }
`;


export default AlertManager;
