import moment from "moment";
import { addNotification } from "Redux/actions";
import store from "Redux/store";

export * from "./xFetch";
export * from "./custom-hooks";
export * from "./analytics";

export const counter = (count = 10, cb) => {
    const arr = [];
    for (let i = 0; i < count; i++) {
        arr[i] = cb(i);
    }
    return arr;
};

/**
 * @param {number} time - time to sleep in ms
 */
export const sleep = async (time) =>
    new Promise((resolve) => {
        window.setTimeout(resolve, time);
    });

export const getClassLecName = (lecClass) => {
    const { lecFirstName, lecLastName } = lecClass;
    return `${lecFirstName} ${lecLastName}`;
};

export function throttle(func, timeFrame) {
    var lastTime = 0;
    return function () {
        var now = new Date();
        if (now - lastTime >= timeFrame) {
            func();
            lastTime = now;
        }
    };
}

export function isClassLecturer(sess, user) {
    return sess.lecturerId == user.userId || sess.assistantlecturerId == user.userId;
}

export function getMonday(date) {
    return moment(date).day(1).format("YYYY-MM-DD");
}

export function getFullPeriodWeeks(data) {
    const { startDate, endDate } = data;
    const dateA = moment(startDate).day(1);
    const period = new Map();
    while (dateA.isBefore(endDate)) {
        period.set(dateA.format("YYYY-MM-DD"), 0);
        dateA.add(1, "week");
    }
    return period;
}

export async function copyTextToClipboard(text) {
    await navigatorCopyTextToClipboard(text);
    store.dispatch(
        addNotification({
            message: "Copied to Clipboard!",
            autoHideDuration: 2000,
        })
    );
    return;

    function fallbackCopyTextToClipboard(text) {
        return new Promise((resolve, reject) => {
            var textArea = document.createElement("textarea");
            textArea.value = text;

            // Avoid scrolling to bottom
            textArea.style.top = "0";
            textArea.style.left = "0";
            textArea.style.position = "fixed";

            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();

            try {
                var successful = document.execCommand("copy");
                successful ? resolve() : reject();
            } catch (err) {
                reject(err);
            }

            document.body.removeChild(textArea);
        });
    }

    function navigatorCopyTextToClipboard(text) {
        if (!navigator.clipboard) {
            return fallbackCopyTextToClipboard(text);
        }
        return navigator.clipboard.writeText(text);
    }
}

export function nthFormatWeekString(weekStr) {
    const n = /\d+/.exec(weekStr)[0] || "N";
    if (n == 1) return `${n}st`;
    else if (n == 2) return `${n}nd`;
    else if (n == 3) return `${n}rd`;
    else return `${n}th`;
}
