import { ACTIONS } from './actions';

export function user(state = null, { type, payload }) {
    switch (type) {
        case 'user/recievedData': {
            const { user } = payload;
            if (user) {
                return { ...user };
            }
            return state;
        }
        case 'user/loggedOut': {
            return null;
        }
        default: return state;
    }
}

export function university(state = null, { type, payload }) {
    switch (type) {
        case 'user/recievedData': {
            const { university } = payload;
            if (university) {
                return { ...university };
            }
            return state;
        }
        case 'user/loggedOut': {
            return null;
        }
        default: return state;
    }
}

export function classes(state = [], { type, payload }) {
    switch (type) {
        case 'user/recievedData': {
            const { classes } = payload;
            if (classes) {
                return [...classes];
            }
            return state;
        }
        case 'user/loggedOut': {
            return [];
        }
        default: return state;
    }
}

export function lecturers(state = [], { type, payload }) {
    switch (type) {
        case 'user/recievedData': {
            const { lecturers } = payload;
            if (lecturers) {
                return [...lecturers];
            }
            return state;
        }
        case 'user/loggedOut': {
            return [];
        }
        default: return state;
    }
}

export function selectedClass(state = null, { type, payload }) {
    switch (type) {
        case 'user/selectedClass': {
            return payload;
        }
        case 'user/recievedData': {
            const { classes } = payload;
            if (!classes) return state;
            if (!state && classes[0]) {
                return { ...classes[0] };
            }
            if (state && classes[0]) {
                const selClass = classes.find(c => c.classId == state.classId);
                return { ...selClass };
            }
            return state;
        }
        case 'user/loggedOut': {
            return null;
        }
        default: return state;
    }
}

export function admins(state = [], { type, payload }) {
    switch (type) {
        case 'user/recievedData': {
            const { admins } = payload;
            if (admins) {
                return [...admins];
            }
            return state;
        }
        case 'user/loggedOut': {
            return [];
        }
        default: return state;
    }
}

export function role(state = '', { type, payload }) {
    switch (type) {
        case 'user/changedRole': {
            if (/^(administrator|lecturer|student)$/i.test(payload)) {
                return payload.toLowerCase();
            }
            return state;
        }
        case 'user/loggedOut': {
            return '';
        }
        default: return state;
    }
}

export function period(state = 'all', { type, payload }) {
    switch (type) {
        case 'lec/changedPeriod': {
            return payload;
        }
        default: return state;
    }
}

export function chats(state = {}, { type, payload }) {
    switch (type) {
        case 'user/recievedData': {
            const { chats } = payload;
            if (chats) {
                const chatsObj = {};
                for (let c of chats) {
                    chatsObj[c.groupId] = c.chats;
                }
                return chatsObj;
            }
            return state;
        }
        case 'system/recieveChat': {
            const { groupId, chatHash, pending } = payload;
            const groupChats = [...(state[groupId] || [])];
            if (chatHash && !pending) {
                const idx = groupChats.findIndex(gc => gc.chatHash === chatHash);
                if (idx > -1) {
                    delete payload.chatHash;
                    groupChats[idx] = payload;
                }
            } else {
                groupChats.push(payload);
            }
            return { ...state, [groupId]: groupChats };
        }
        default: return state;
    }
}

export function tasks(state = {}, { type, payload }) {
    switch (type) {
        case 'user/recievedData': {
            const { tasks } = payload;
            if (tasks) {
                const taskObj = {};
                for (let t of tasks) {
                    taskObj[t.groupId] = t.tasks;
                }
                return taskObj;
            }
            return state;
        }
        case 'user/recievedTasks': {
            const { groupId, tasks } = payload;
            return { ...state, [groupId]: tasks };
        }
        default: return state;
    }
}


export function pending(state = [], { type, payload }) {
    switch (type) {
        case 'system/setPendingAction': {
            const newState = [...state];
            newState.push(payload);

            return newState;
        }
        case 'system/unsetPendingAction': {
            const newState = [...state];
            const i = newState.findIndex(spa => spa === payload);
            if (i > -1) {
                newState.splice(i, 1);
            }

            return newState;
        }
        default: return state;
    }
}



export function notifications(state = [], action) {
    switch (action.type) {
        case 'notification/added': {
            return [...state, action.payload]
        }
        case 'notification/edited': {
            const idx = state.findIndex(n => n.key === action.payload.key);
            if (idx > -1) {
                state[idx].dismissed = true;
            }
            const notif = action.payload;
            notif.key = notif.newKey;
            delete notif.newKey;
            return [...state, { ...notif }];
        }
        case 'notification/dismissed': {
            const idx = state.findIndex(n => n.key === action.payload);
            console.log(idx);
            if (idx > -1) {
                state[idx].dismissed = true;
            }
            return [...state];
        }
        case 'notification/removed': {
            const idx = state.findIndex(n => n.key === action.payload);
            if (idx > -1) {
                state.splice(idx, 1);
            }
            return [...state];
        }
        default: return state;
    }
}