import MomentUtils from '@date-io/moment';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import AlertManager from 'Components/AlertManager';
import { SnackbarProvider } from 'notistack';
import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import theme from 'Utils/theme';
import '../src/index.css';
import '../src/index.font.css';
import App from './App';
import store from './Redux/store';


function Root() {    
    return (
        <Provider store={store} >
            <MuiThemeProvider theme={theme} >
                <ThemeProvider theme={theme} >
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <BrowserRouter>
                            <SnackbarProvider 
                                autoHideDuration={3500} 
                                domRoot={document.getElementById('notification-root')}
                            >
                                <AlertManager />
                                <App />
                            </SnackbarProvider>
                        </BrowserRouter>
                    </MuiPickersUtilsProvider>
                </ThemeProvider>
            </MuiThemeProvider>
        </Provider>
    );
}

export default Root;